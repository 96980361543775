.canvas-container {
    /* position:absolute !important;  */
    height:100% !important;
    width: 100% !important;
}

canvas{
    height:100% !important;
    width: 100% !important;
}

#storyDiv{
    background-color:white;
}

.layerRow{
    margin-top:10px;
    border-top:1px solid #606060;
    border-bottom:1px solid #606060;
    height:50px;
}


/* Hide arrow on input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type=number] {
    -moz-appearance: textfield;
  } */

  .custom-toggle input:checked+.custom-toggle-slider {
    border: 1px solid #172b4d;
  }
  .custom-toggle input:checked+.custom-toggle-slider:before{
    background: #172b4d;
  }