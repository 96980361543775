html, body, #root, .page, .page-main {
    /* background-color: rgb(96, 96, 96); */
}

.grid-container {
    height: calc(100vh - 34.38px);
    display: grid;
    grid-template-columns: 0.4fr 1.6fr;
    grid-template-rows: 40px 0.7fr 1.3fr;
    grid-template-areas: "General ObjectConfiguration" "Preview Preview" "Layers Layers";
    /* grid-gap: 0 5px; */
    /* background-color: rgb(96, 96, 96); */
}

.Preview {
    grid-area: Preview;
}

.Layers {
    grid-area: Layers;
    overflow-y: scroll;
}

.General {
    grid-area: General;
}

.ObjectConfiguration {
    grid-area: ObjectConfiguration;
}

.dock-gallery {
    width: 25%;
    height: 100%;
    z-index: 1001;
    position: absolute;
    background-color: #293039;
    top: 0;
    left: 80px;
    display: none;
    padding: 15px
}

.dock-gallery-extended {
    display: block;
}

.dock-menu {
    min-width: 80px;
    background-color: #0e1318;
    color: #aaacad;
    user-select: none;
}

.dock-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 10px 0;
}

.dock-item:hover {
    background-color: #293039;
    color: #fff;
}

.dock-item.dock-item-selected {
    background-color: #293039;
    color: #fff;
}

.dock-item-img {
    align-self: center;
    font-size: 1.3rem;
}

.dock-item-title {
    margin-top: 4px;
    align-self: center;
}